import {useUser} from "../../../hooks/api/users/useUser";
import {Conversation} from "@hint-v2/api/models/guidance/v1/models_pb";
import useAgoraTextChat from "../../../hooks/useAgora";
import {useCallback, useEffect, useRef, useState} from "react";
import AC, {AgoraChat} from "agora-chat";
import {SendMsgResult} from "agora-chat/types/engineCore";
import InfiniteScroll from "react-infinite-scroll-component";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import {useConversationActiveSession} from "../../../hooks/api/conversations/useConversationActiveSession";
import {usePingSession} from "../../../hooks/api/conversations/usePingSession";

interface ChatProps {
    conversation: Conversation.AsObject
}

export function Chat({conversation}: ChatProps) {
    const {data: user} = useUser(conversation.userId)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [message, setMessage] = useState("")
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const {data: activeSession} = useConversationActiveSession(conversation.id)

    let {messages, sendTextMessage, loadMoreMessages, agoraToken} = useAgoraTextChat({
        groupId: conversation.textChatConnectionDetails?.agoraGroupId!,
        appKey: "71993575#1159483",
        messagesPerPage: 2,
    })
    usePingSession(activeSession?.id)

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        if (scrollContainer) {
            const currentScrollHeight = scrollContainer.scrollHeight;

            const checkScrollPosition = () => {
                if (scrollContainer.scrollHeight !== currentScrollHeight) {
                    // Scroll height changed, adjust scroll position
                    scrollContainer.scrollTop += scrollContainer.scrollHeight - currentScrollHeight;
                }
                console.log("observer", scrollContainer.scrollTop, scrollContainer.scrollHeight, currentScrollHeight)
            };

            const observer = new MutationObserver(checkScrollPosition);
            observer.observe(scrollContainer, { childList: true, subtree: true });

            return () => {observer.disconnect();} // Cleanup on unmount
        }
    }, [scrollContainerRef]); // Only run when messages change


    return <div className={"flex flex-col flex-grow rounded-t-[12px]  border border-gray-300 bg-white"}>
        <div
            className={"w-full flex flex-col bg-gray-200 py-4 content-center items-center gap-[6px] box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.10)]"}>
            <p className={"text-[24px]"}>Chat With {user?.firstName?.value} {user?.lastName?.value}</p>
            <p className={"text-[18px]"}>Ilene hasn't started a new conversation with you yet</p>
        </div>
        <div className={"h-[300px] max-h-[300px] overflow-y-hidden"}>
            <div id={"messages"}
                 ref={scrollContainerRef}
                 className={"w-full max-h-full h-full bg-pink-50 overflow-y-auto"}>
                <InfiniteScroll
                    scrollableTarget="messages"
                    className={"flex flex-col-reverse gap-2 p-10 overflow-y-auto max-h-full h-full"} next={loadMoreMessages} hasMore={hasMore}
                    loader={<div>Loading</div>} dataLength={messages.length}>
                    {[...messages].reverse().map((message, index) => {
                        if (message.type === "txt") {
                            let pos = message.from === agoraToken?.agoraUserId ? "self-end" : "self-start"
                            return <div className={`${pos} bg-gray-100 p-5`} key={index}> {message.msg}</div>
                        }
                        return <div key={index}>{message.type}</div>
                    })}
                </InfiniteScroll>
            </div>
        </div>
        <Input value={message} onChange={setMessage}/>
        <Button onClick={() => sendTextMessage(message)}>Send</Button>
    </div>
}