import {useContext, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import {RpcError} from "grpc-web";
import {APIError, wrapClientError} from "../errors";
import {LoginRequest, LoginResponse} from "@hint-v2/api/commonapis/auth/v1/auth_pb";
import {Role, RoleValue} from "@hint-v2/api/models/auth/v1/enum_pb";
import {useNotifications} from "../../../hoc/notifications/WithNotificationsManager";


export function useLogin(): [(login: string, password: string) => Promise<string>, boolean] {
    const clients = useContext(APIClientsContext)
    const {addNotification} = useNotifications()
    const [isLoading, setIsLoading] = useState(false);
    const authClient = clients?.auth
    if (!authClient) {
        throw new Error("Auth client not found")
    }

    return [async (login: string, password: string): Promise<string> => {
        let request = new LoginRequest()
            .setRole(
                new RoleValue().setValue(Role.ROLE_ASTROLOGER)
            )
            .setEmailPassword(
                new LoginRequest.EmailPassword()
                    .setEmail(login)
                    .setPassword(password)
            );
        setIsLoading(true)
//
        return new Promise<string>((resolve, reject: (_: APIError) => void) => {
            clients?.auth.login(request, {}).then((resp: LoginResponse) => {
                setIsLoading(false)
                resolve(resp.getAccessToken())
            }).catch((e: RpcError) => {
                setIsLoading(false)
                addNotification({
                    title: "Login",
                    message: `Login failed: ${e.message}`,
                    type: "error",
                    hideAfter: 2000,
                })
                reject(wrapClientError(e))
            })
        })
    }, isLoading]
}