import React from "react";
import {RouteObject} from "react-router-dom";
import LoginPage from "./LoginPage";
import WithAuthorization from "../../hoc/authorization/WithAuthorization";

export const routes: RouteObject[] = [
    {
        path: "/login",
        element: <WithAuthorization><LoginPage/></WithAuthorization>
    }
]