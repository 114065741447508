import Logo from "../logo/Logo";
import starIcon from "../../resources/icons/star.svg";
import smileFaceIcon from "../../resources/icons/face_smiling.svg";
import angryFaceIcon from "../../resources/icons/face_angry.svg";
import {Shimmer} from "react-shimmer";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";

interface AstrologerRatingProps {
    astrologer?: Astrologer.AsObject,
}

function AstrologerRating({astrologer}: AstrologerRatingProps) {
    return <div className="flex flex-row gap-[8px]">
        <div
            className={"flex flex-row items-center px-[18px] py-[15px] rounded-[16px] border-[1px] border-gray-50 bg-gray-25"}>
            <img src={starIcon} alt={"rating"}/>
            <p className={"text-secondary-warning font-sf-pro font-semibold text-[17px] leading-[22px]"}>
                {astrologer ? astrologer.rating.toPrecision(2) : <Shimmer height={15} width={15}/>}
            </p>
        </div>
        <div
            className={"flex flex-row items-center gap-[8px] px-[18px] py-[15px] rounded-[16px] border-[1px] border-gray-50 bg-gray-25"}>
            <img src={smileFaceIcon} alt={"happy users"}/>
            <p className={"text-secondary-success font-sf-pro font-semibold text-[17px] leading-[22px]"}>
                {astrologer ? astrologer.happyUsersCount : <Shimmer height={15} width={15}/>}
            </p>
        </div>
        <div
            className={"flex flex-row items-center gap-[8px] px-[18px] py-[15px] rounded-[16px] border-[1px] border-gray-50 bg-gray-25"}>
            <img className={"w-[24px] h-[24px]"} src={angryFaceIcon} alt={"unhappy users"}/>
            <p className={"text-secondary-error font-sf-pro font-semibold text-[17px] leading-[22px]"}>
                {astrologer ? astrologer.unhappyUsersCount : <Shimmer height={15} width={15}/>}
            </p>
        </div>
    </div>
}

export default AstrologerRating;