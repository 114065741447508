import {RpcError} from "grpc-web";

export interface APIError {
    code: number
    message: string
}

export function wrapClientError(e: RpcError): APIError {
    return {
        code: e.code,
        message: e.message,
    }
}