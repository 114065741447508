import React, {useState} from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Input from "../../components/form/Input";
import Button, {ButtonColor} from "../../components/form/Button";
import useSession from "../../hooks/useSession";
import {useLogin} from "../../hooks/api/auth/useLogin";

function LoginPage() {
    let [email, setEmail] = useState<string>("");
    let [password, setPassword] = useState<string>("");
    let [loginRequest, processing] = useLogin()
    let [, setSession] = useSession();

    const login = async () => {
        try {
            let token = await loginRequest(email, password)
            console.log(token)
            setSession(token)
        } catch (e) {
            console.log(e)
        }
    }

    console.log(setSession)

    return (
        <div className={"w-screen h-screen flex flex-col max-h-screen min-h-screen overflow-auto"}>
            <Header/>
            {processing && <div className={"w-full h-full bg-black bg-opacity-50 fixed z-50 flex justify-center items-center"}> Processing</div>}
            <div className="flex flex-col items-center flex-grow gap-2  py-[32px] px-[112px]  overflow-auto max-h-full">
                <Input placeholder={"Email"} onChange={setEmail}/>
                <Input placeholder={"Password"} onChange={setPassword}/>
                <Button.Blue onClick={() => login()}> Login </Button.Blue>
            </div>
            <Footer/>
        </div>
    );
}

export default LoginPage;


