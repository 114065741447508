import {Shimmer} from "react-shimmer";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";

interface StatusTagProps {
    status?: Astrologer.Status,
}

const statusMappings = {
    [Astrologer.Status.STATUS_BUSY]: { colorClass: "bg-[#FF9649]", text: "Busy" },
    [Astrologer.Status.STATUS_ONLINE]: { colorClass: "bg-[#6CE9A6]", text: "Online" },
    [Astrologer.Status.STATUS_OFFLINE]: { colorClass: "bg-gray-100", text: "Offline" },
};

function StatusTag(props: StatusTagProps) {
    if(props.status === Astrologer.Status.STATUS_UNSPECIFIED) {
        return <></>
    }
    if(!props.status) {
        return <Shimmer height={21} width={60}/>
    }

    const {colorClass, text} = statusMappings[props.status]

    return <div className={`flex flex-col justify-center w-[60px] h-[21px] rounded-[12px] ${colorClass}`}>
        <p className={`font-sf-pro font-semibold text-[11px] leading-[13px] text-center text-white `}>{text}</p>
    </div>
}

export default StatusTag;