import {useContext, createContext, ReactElement, useState, Component} from "react";

export interface Notification {
    id: number,
    type?: "info" | "success" | "warning" | "error"
    icon?: ReactElement | string,
    title: string,
    message: string,
    createdAt: Date,
    hideAt: Date,
    className?: string
    notClosable?: boolean
    showProgressBar?: boolean
    customActions?: (_: Notification) => ReactElement[] | ReactElement
    progressBarTitle?: (millisecondsLeft: number) => ReactElement
}

export interface NotificationBasicParams {
    type?: "info" | "success" | "warning" | "error"
    icon?: ReactElement | string,
    title: string,
    message: string,
    className?: string
    notClosable?: boolean
    showProgressBar?: boolean
    customActions?: (_: Notification) => ReactElement
    progressBarTitle?: (millisecondsLeft: number) => ReactElement
}

export interface NotificationWithDurationParams {
    hideAfter: number,
}

export interface NotificationWithDeadlineParams {
    createdAt?: Date,
    deadline: Date,
}

export type NewNotificationParams =
    NotificationBasicParams
    & (NotificationWithDurationParams | NotificationWithDeadlineParams)

export interface NotificationsManager {
    notifications: Array<Notification>,
    addNotification: (notification: NewNotificationParams) => void,
    removeNotification: (notification: Notification) => void,
    clearNotifications: () => void,
}


function createDefaultNotificationsManager(): NotificationsManager {
    return {
        notifications: [],
        addNotification: () => {
        },
        removeNotification: () => {
        },
        clearNotifications: () => {
        },
    };
}

export const NotificationsManagerCtx = createContext<NotificationsManager>(createDefaultNotificationsManager());


interface WithNotificationsManagerProps {
    children: ReactElement | ReactElement[]
}

export function WithNotificationsManager(props: WithNotificationsManagerProps) {
    let [notifications, setNotifications] = useState<Array<Notification>>([]);
    const addNotification = (params: NewNotificationParams) => {
        let createdAt = new Date()
        let hideAt = new Date()
        if ("hideAfter" in params) {
            hideAt.setMilliseconds(hideAt.getMilliseconds() + params.hideAfter)
        } else if ("deadline" in params) {
            if (params.createdAt) createdAt = params.createdAt
            hideAt = params.deadline
        }

        if (Date.now() > hideAt.getTime()) {
            return;
        }

        let notification: Notification = {
            ...params,
            id: Date.now(),
            createdAt: createdAt,
            hideAt: hideAt,
        }
        console.log(notification)
        setNotifications([...notifications, notification]);
        if (notification.hideAt) setTimeout(() => {
            removeNotification(notification)
        }, notification.hideAt.getTime() - Date.now())
    }

    const removeNotification = (notification: Notification) => {
        setNotifications((prevNotifications) => {
            let newNotifications = [...prevNotifications];
            return newNotifications.filter((newNotification) => newNotification !== notification)
        })
    }

    const clearNotifications = () => {
        setNotifications([])
    }

    return <NotificationsManagerCtx.Provider
        value={{
            notifications,
            addNotification,
            removeNotification,
            clearNotifications
        }}>{props.children}</NotificationsManagerCtx.Provider>;
}

export function useNotifications() {
    return useContext(NotificationsManagerCtx);
}