import {useContext, useEffect, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import {APIError, wrapClientError} from "../errors";
import useSession from "../../useSession";
import {RpcError} from "grpc-web";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {useNotifications} from "../../../hoc/notifications/WithNotificationsManager";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";
import {SetMyStatusRequest} from "@hint-v2/api/astrologerapis/guidance/v1/astrolgers_pb";

import SessionAudioIcon from "../../../resources/icons/session_audio.svg"
import Button from "../../../components/form/Button";

export function useSetAstrologerStatusMutation(): UseMutationResult<Astrologer.AsObject | void, APIError, Astrologer.Status, void> {
    const clients = useContext(APIClientsContext)
    const {addNotification} = useNotifications()
    const [session] = useSession()
    const astrologersClient = clients!.astrologers
    const queryClient = useQueryClient()
    // Queries
    return useMutation({
        onSuccess: () => {
            addNotification({
                title: "Status update",
                message: `Success`,
                icon: SessionAudioIcon,
                type: "success",
                hideAfter: 20000,
                notClosable: true,
                showProgressBar: true,
                customActions: (notification) => {
                    return <div className={"flex flex-row gap-[3px]"}>
                        <Button className={"w-min min-w-0"}>Ok</Button>
                        <Button className={"w-min min-w-0"}>Cancel</Button>
                    </div>
                },
                progressBarTitle: (millisecondsLeft) => {
                    return <span className={"text-gray-600 text-[13px] leading-[18px] font-sf-pro min-w-[330px]"}>
                        After <span className={"inline-block text-error-500 w-[40px]"}>
                        {Math.floor(millisecondsLeft / 1000)}sec</span>
                        the request will be automatically declined
                    </span>
                }
            })
            return queryClient.invalidateQueries(["me"])
        },
        onError: (e: RpcError) => {
            addNotification({
                title: "Status update",
                message: `Failed to change your status: ${e.message}`,
                type: "error",
                hideAfter: 2000,
                notClosable: true,
            })
            return Promise.reject(wrapClientError(e))
        },
        mutationFn: (status: Astrologer.Status) => {
            let req = new SetMyStatusRequest()
                .setStatus(status);

            return astrologersClient.setMyStatus(req, {
                authorization: session,
            }).then(() => {
            })
        },
    })
}