import {useContext, useEffect, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import useSession from "../../useSession";
import {
    useQuery,
    UseQueryResult
} from "@tanstack/react-query";
import {RpcError} from "grpc-web";
import {Conversation} from "@hint-v2/api/models/guidance/v1/models_pb";
import {
    ListConversationsRequest,
    ListConversationsResponse
} from "@hint-v2/api/astrologerapis/guidance/v1/conversation_pb";

export function useConversations(): UseQueryResult<Conversation.AsObject[], RpcError> {
    const clients = useContext(APIClientsContext)
    const [session] = useSession()

    // Queries
    return useQuery({
        queryKey: ["conversations"],
        queryFn: () => {
            const req = new ListConversationsRequest()
            return clients?.conversations.listConversations(req, {
                authorization: session,
            }).then((resp: ListConversationsResponse): Conversation.AsObject[] => {
                return resp.toObject().dataList
            })
        },
    })
}