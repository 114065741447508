import {useContext,} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import useSession from "../../useSession";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {RpcError} from "grpc-web";
import {Session} from "@hint-v2/api/models/guidance/v1/models_pb";
import {GetActiveSessionRequest} from "@hint-v2/api/astrologerapis/guidance/v1/session_pb";

export function useConversationActiveSession(conversationId: string): UseQueryResult<Session.AsObject, RpcError> {
    const clients = useContext(APIClientsContext)
    const [session] = useSession()

    // Queries
    return useQuery({
        queryKey: ["activeSession", conversationId],
        queryFn: () => {
            const req = new GetActiveSessionRequest()
                .setConversationId(conversationId)
            return clients?.sessions.getActiveSession(req, {
                authorization: session,
            }).then((resp: Session): Session.AsObject => {
                return resp.toObject()
            })
        },
    })
}