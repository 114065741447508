import {User} from "@hint-v2/api/models/user/v1/models_pb";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";


interface UserBirthdayInfoProps {
    user: User.AsObject
}

export default function UserBirthdayInfo({user}: UserBirthdayInfoProps) {
    const birthDate = user.birthDate && new Timestamp()
        .setSeconds(user.birthDate.seconds)
        .setNanos(user.birthDate.nanos)
        .toDate();

    const birthDay = birthDate && birthDate.toLocaleDateString()
    const birthTime = birthDate && birthDate.toLocaleTimeString()
    const birthPlace = user.birthPlace && `${user.birthPlace.city}, ${user.birthPlace.country}`

    const items = [
        {title: "Date of birth", content: birthDay},
        {title: "Time of birth", content: birthTime},
        {title: "Place of birth", content: birthPlace},
    ];

    const itemClassName = (value: string | undefined) => {
        return
    }


    return <div className={"flex flex-col gap-[6px]"}>
        <h3 className={"font-semibold text-[18px] text-gray-700 leading-[28px]"}>BIRTHDAY</h3>
        <div className={"flex flex-row gap-4"}>
            {items.map((tag) => {
                return <div>
                    <p className={`font-[500] text-gray-700`}>{tag.title}</p>
                    <p className={
                        `px-[14px] py-[12px] rounded-[12px] `+
                        `border border-gray-300 `+
                        `leading-[24px] `+
                        `${tag.content?' font-semibold':'text-gray-500'}`}>{tag.content ?? "Unknown"}</p>
                </div>
            })}
        </div>
    </div>
}