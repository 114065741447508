import React, {ReactElement, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Cookies, withCookies} from 'react-cookie';
import {SessionContext} from "./SessionContext";
import useSession from "../../hooks/useSession";

interface AuthorizedProps {
    cookies: Cookies
    children: ReactElement | ReactElement[]
}

function WithAuthorization(props: AuthorizedProps) {
    const [session] = useSession()
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location?.pathname;

    useEffect(() => {
        if (pathname == "/login" && session) {
            navigate("/");
        }
        if (pathname != "/login" && !session) {
            navigate("/login");
        }
    }, [pathname, session]);

    return <>{props.children}</>;
}

export default withCookies(WithAuthorization);