import {Notification, useNotifications} from "../../hoc/notifications/WithNotificationsManager";
import CrossIcon from "../../resources/icons/cross.svg";
import React, {isValidElement, ReactElement, useEffect, useState} from "react";
import {animated, useSpring} from 'react-spring';
import Button from "../form/Button";

const bgColors: Record<NonNullable<Notification['type']>, String> = {
    info: "bg-blue-100",
    success: "bg-green-100",
    warning: "bg-yellow-100",
    error: "bg-rose-100",
}

interface ProgressBarProps {
    startTime: Date;
    endTime: Date;
}

function NotificationPopUp({notification}: { notification: Notification }) {
    const {removeNotification} = useNotifications()

    let icon: ReactElement | undefined;
    if (notification.icon) {
        icon = isValidElement(notification.icon) ?
            notification.icon :
            <img src={notification.icon} className={"w-[40px] h-[40px]"}/>
    }

    const paddingClass = `py-[9px] pl-[16px] ${notification.notClosable ? 'pr-[16px]' : 'pr-[30px]'}`
    const colorClass = bgColors[notification.type ?? "info"]
    const [percent, setPercent] = useState(100);
    const [millisecondsLeft, setMillisecondsLeft] = useState(0);

    const calculateTimePercent = () => {
        const now = new Date().getTime();
        const totalDuration = notification.hideAt.getTime() - notification.createdAt.getTime();
        const passedDuration = now - notification.createdAt.getTime();

        const newPercent = 100-(passedDuration / totalDuration) * 100;
        console.log("new percent", newPercent)
        setPercent(newPercent);
        setMillisecondsLeft(totalDuration - passedDuration)
    };

    useEffect(() => {
        const intervalId = setInterval(calculateTimePercent, 200); // adjust the interval as needed

        return () => {
            clearInterval(intervalId);
        };
    }, [notification.createdAt, notification.hideAt]);

    const springProps = useSpring({
        from: {width: "0%"},
        to: {width: `${percent}%`},
    });


    return <div
        className={`relative flex flex-col gap-[10px] rounded-[12px] ${paddingClass} ${colorClass}  ${notification.className}`}>
        {
            !notification.notClosable &&
            <div className={"absolute top-[6px] right-[6px]"}>
                <button onClick={() => {
                    removeNotification(notification)
                }} className={"ml-auto self-start"}><img src={CrossIcon} alt={"close"}/></button>
            </div>
        }
        <div className={`flex flex-col gap-[8px]`}>
            <div className={`flex flex-row gap-[12px] items-center`}>
                {icon}
                <div className={"flex flex-col gap-[2px]"}>
                    <span className={"font-semibold"}>{notification.title}</span>
                    <span
                        className={"text-gray-600 font-[400] text-[14px] leading-[20px]"}>{notification.message}</span>
                </div>
                <div className={"flex-1"}></div>
                <div className={"self-stretch"}>
                    {notification.customActions && notification.customActions(notification)}
                </div>
            </div>
            {
                notification.showProgressBar &&
                <div className={"w-full bg-gray-300 h-[4px] rounded-full overflow-hidden"}>
                    <animated.div className={"h-full bg-primary-blue"} style={{...springProps}}/>
                </div>
            }
            {
                notification.progressBarTitle &&
                <div>{notification.progressBarTitle(millisecondsLeft)} </div>
            }
        </div>
    </div>
}

export function Notifications() {
    const {notifications, clearNotifications} = useNotifications()

    return <div className={"flex flex-col gap-[12px]"}>
        {notifications.map((notification) => (
            <NotificationPopUp notification={notification} key={notification.id}/>
        ))}
        {
            notifications.length > 5 && <Button onClick={clearNotifications}>Clear all notification</Button>
        }
    </div>

}