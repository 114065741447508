import {useContext, useEffect, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import useSession from "../../useSession";
import {
    useQuery,
    useQueryClient,
    UseQueryResult
} from "@tanstack/react-query";
import {RpcError} from "grpc-web";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";

export function useAstrologerInfo(): UseQueryResult<Astrologer.AsObject, RpcError> {
    const queryClient = useQueryClient()
    const clients = useContext(APIClientsContext)
    const [session] = useSession()
    const guidanceClient = clients!.astrologers

    // Queries
    return useQuery({
        queryKey: ["me"],
        queryFn: () => {
            return guidanceClient.getMe(new google_protobuf_empty_pb.Empty(), {
                authorization: session,
            }).then((resp: Astrologer): Astrologer.AsObject => {
                console.log(resp)
                return resp.toObject()
            })
        },
    })
}