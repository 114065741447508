import React from 'react';
import './App.css';
import {BrowserRouter, Route, RouterProvider, Routes} from "react-router-dom";
import {routes} from "./router";
import WithAuthorization from "./hoc/authorization/WithAuthorization";
import {WithAPIClients} from "./hoc/api/WithAPIClient";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {WithNotificationsManager} from "./hoc/notifications/WithNotificationsManager";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // 5 minutes
        },
    },
})

function App() {
    return (
        <BrowserRouter>
            <WithNotificationsManager>
                <WithAPIClients>
                    <WithAuthorization>
                        <QueryClientProvider client={queryClient}>
                            <Routes>
                                {routes.map((r) =>
                                    <Route key={r.path} path={r.path} element={r.element}></Route>
                                )}
                            </Routes>
                        </QueryClientProvider>
                    </WithAuthorization>
                </WithAPIClients>
            </WithNotificationsManager>
        </BrowserRouter>
    );
}

export default App;
