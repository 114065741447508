import React from "react";
import {RouteObject} from "react-router-dom";
import WithAuthorization from "../../hoc/authorization/WithAuthorization";
import Dashboard from "./dashboard/Dashboard";
import Readings from "./readings/Readings";
import Rating from "./rating/Rating";
import Statistic from "./statistic/Statistic";
import Profile from "./profile/Profile";

export const routes: RouteObject[] = [
    {
        path: "/",
        element: <Dashboard/>,
    },
    {
        path: "/profile",
        element: <Profile/>,
    },
    {
        path: "/readings",
        element: <Readings/>,
    },
    {
        path: "/rating",
        element: <Rating/>,
    },
    {
        path: "/statistic",
        element: <Statistic/>,
    }
]