import Toggle from "../../../components/form/Toggle";
import Input from "../../../components/form/Input";
import React from "react";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";
import Services = Astrologer.Services;
import {parse} from "url";

interface ServiceSettingsProps {
    serviceName: string
    service: Services.Global.AsObject;
    onChanged?: (service: Services.Global.AsObject) => void;
}

function GlobalServiceSettings({serviceName, service, onChanged}: ServiceSettingsProps) {
    return <div className={"flex flex-row gap-[12px] items-center"}>
        <div className={"flex flex-row gap-[8px] items-center px-[14px] py-[12px] w-[220px]"}>
            <Toggle
                checked={service.isProvided}
                onChange={(enabled: boolean) => {
                    onChanged && onChanged({...service, isProvided: enabled})
                }}
            />
            <p>{serviceName}</p>
        </div>
    </div>
}

export default GlobalServiceSettings;
