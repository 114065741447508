import {useCallback, useContext} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import useSession from "../../useSession";
import {RpcError} from "grpc-web";
import {Empty} from "google-protobuf/google/protobuf/empty_pb";
import {GetAgoraTokenResponse} from "@hint-v2/api/commonapis/guidance/v1/agora_pb";
import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";

export function useAgoraToken(): UseQueryResult<GetAgoraTokenResponse.AsObject, RpcError> {
    const clients = useContext(APIClientsContext)
    const [session] = useSession()
    // Queries
    return useQuery({
        queryKey: ["agora_token"],
        queryFn: () => {
            return clients!.agora.getAgoraChatToken(new Empty(), {
                authorization: session,
            }).then((resp: GetAgoraTokenResponse): GetAgoraTokenResponse.AsObject => {
                return resp.toObject();
            })
        },
    })
}