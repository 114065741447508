import LogoImg from './Logo.svg';
import {ImgHTMLAttributes} from "react";

interface LogoProps extends ImgHTMLAttributes<HTMLImageElement> {
}


const Logo: React.FC<LogoProps> = ({ src = LogoImg, alt = 'Hint Logo', ...rest }) => {
    return <img {...rest} src={src} alt={alt} />
}

export default Logo;