import Toggle from "../../../components/form/Toggle";
import Input from "../../../components/form/Input";
import React from "react";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";
import Services = Astrologer.Services;
import {parse} from "url";

interface ServiceSettingsProps {
    serviceName: string
    recommendedPrice: number
    service: Services.Session.AsObject;
    onChanged?: (service: Services.Session.AsObject) => void;
}

function SessionServiceSettings({serviceName, recommendedPrice=4.99, service, onChanged}: ServiceSettingsProps) {
    const [price, setPrice] = React.useState<string>(`${service.pricePerMinute / 100}`)
    const changePrice = (price: string) => {
        if(!price.match(/^([0-9]+\.?[0-9]{0,2})?$/)){
            return
        }

        setPrice(price)
        if(price.length === 0) {
            price = "0"
        }
        if(price.endsWith(".")){
            price = price.slice(0, -1)
        }

        let value = parseFloat(price)
        onChanged && onChanged({...service, pricePerMinute: Math.round(value * 100)})
    }
    return <div className={"flex flex-row gap-[12px] items-center"}>
        <div className={"flex flex-row gap-[8px] items-center px-[14px] py-[12px] w-[220px]"}>
            <Toggle
                checked={service.isProvided}
                onChange={(enabled: boolean) => {
                    onChanged && onChanged({...service, isProvided: enabled})
                }}
            />
            <p>{serviceName}</p>
        </div>
        <div className={"w-[256px]"}>
            <Input
                prefix={'$'}
                value={price}
                onChange={changePrice}
                placeholder={"Enter price per minute"}
            />
        </div>
        <p className={"text-gray-500"}>Recommended price<br/> ${recommendedPrice/100} per minute</p>
    </div>
}

export default SessionServiceSettings;
