import {User} from "@hint-v2/api/models/user/v1/models_pb";
import {Gender} from "@hint-v2/api/common/v1/enums_pb";
import FemaleIcon from './female.png'
import MaleIcon from './male.png'
import {ReactNode} from "react";


const genderValues: Record<Gender, ReactNode> = {
    [Gender.GENDER_UNSPECIFIED]: "",
    [Gender.GENDER_OTHER]: <div className={"flex flex-row gap-[6px]"}>other</div>,
    [Gender.GENDER_MALE]: <div className={"flex flex-row gap-[6px]"}><img src={MaleIcon}/><p>male</p></div>,
    [Gender.GENDER_FEMALE]: <div className={"flex flex-row gap-[6px]"}><img src={FemaleIcon}/><p>female</p></div>,
}

const statusValues: Record<User.RelationshipStatus, string> = {
    [User.RelationshipStatus.RELATIONSHIP_STATUS_UNSPECIFIED]: "",
    [User.RelationshipStatus.RELATIONSHIP_STATUS_SINGLE]: "single",
    [User.RelationshipStatus.RELATIONSHIP_STATUS_IN_RELATIONSHIP]: "in relationship",
}

interface UserProfileMainInfoProps {
    user: User.AsObject
}

export default function UserProfileMainInfo({user}: UserProfileMainInfoProps) {
    const statusMarkerClass = true ? "bg-success-300" : "bg-gray-400"; // TODO: user online/offline status

    let tags = [];
    if (user.gender) {
        tags.push({
            key: "gender",
            content: genderValues[user.gender.value]
        })
    }

    if (user.relationshipStatus) {
        tags.push({
            key: "relationshipStatus",
            content: statusValues[user.relationshipStatus.value]
        })
    }

    return <div className={"flex flex-row items-center gap-3"}>
        <div className={"relative w-[72px] h-[72px]"}>
            <div
                className={`absolute z-10 rounded-full ${statusMarkerClass} w-[17px] h-[17px] stroke-[1px] border border-white`}></div>
            <img className={"absolute z-0 rounded-[39px]"}
                 alt={`${user.firstName?.value} ${user.lastName?.value} avatar`}
                 src={`https://i.pravatar.cc/150?img=${user.id}`}/>
        </div>
        <div className={"flex flex-col gap-[6px]"}>
            <div
                className={" text-[30px] line-[38px]"}>{`${user.firstName?.value} ${user.lastName?.value}`}</div>
            <div className={"flex flex-row gap-4"}>
                {tags.map((tag) => {
                    return <div key={tag.key}
                                className={"flex flex-row gap-[6px] rounded-[12px] px-[14px] py-[6px] bg-white border-[1px] border-gray-300"}>
                        {tag.content}
                    </div>
                })}
            </div>
        </div>
    </div>
}