import React, {useState} from 'react';

interface ToggleProps {
    checked: boolean,
    onChange?: (status: boolean) => void
}

function Toggle({checked, onChange = () => {}}: ToggleProps) {
    const toggle = () => onChange(!checked);

    return (
        <label className="flex items-center cursor-pointer">
            <div className="relative">
                <input type="checkbox" className="sr-only" checked={checked} onChange={toggle}/>
                <div
                    className={`dot z-10 absolute left-[2px] top-[2px] transition duration-200 ease-in-out bg-white rounded-full shadow w-[28px] h-[28px] ${checked ? 'transform translate-x-[20px]' : ''}`}></div>
                <div
                    className={`block z-0 bg-[#066fdeff] w-[52px] h-8 rounded-full ${checked ? 'bg-blue-600' : 'bg-gray-400'}`}></div>
            </div>
        </label>
    );
}

export default Toggle;
