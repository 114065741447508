import {useRef} from "react";

interface InputProps {
    value?: string
    placeholder?: string
    disabled?: boolean
    className?: string
    prefix?: string
    onChange?: (e: string) => void
}

function Input(props: InputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    return <div tabIndex={-1} onClick={focusInput}
                className={`flex w-full pr-[14px] py-[12px] gap-[3px] rounded-[12px] bg-white border border-gray-300 focus-within:border-blue-500 focus-within:ring ${props.className || ''}`}>
        {props.prefix && <span className="pl-[10px] text-gray-600">{props.prefix}</span>}
        <input ref={inputRef} className={`w-full outline-none ${!props.prefix && 'pl-[10px]'}`}
               onChange={(e) => props.onChange && props.onChange(e.target.value)}
               disabled={props.disabled}
               placeholder={props.placeholder}
               value={props.value}>
        </input>
    </div>
}

export default Input;
