import PaceIcon from "../../../resources/icons/pace.svg"
import {Conversation} from "@hint-v2/api/models/guidance/v1/models_pb";
import {useConversations} from "../../../hooks/api/conversations/useConversations";
import {useUser} from "../../../hooks/api/users/useUser";
import {User} from "@hint-v2/api/models/user/v1/models_pb";
import Interest = User.Interest;

const interestValues: Record<Interest, string> = {
    [Interest.INTEREST_UNSPECIFIED]: "",
    [Interest.INTEREST_WELLBEING_AND_VITALITY]: "Wellbeing & Vitality",
    [Interest.INTEREST_LOVE_AND_RELATIONSHIP]: "Love & Relationship",
    [Interest.INTEREST_CAREER_AND_DESTINY]: "Career & Destiny",
}

interface ConversationItemProps {
    conversation: Conversation.AsObject
    onClick?: () => void
}

function ConversationItem(props: ConversationItemProps) {
    const {data: user} = useUser(props.conversation.userId)

    const statusColor = true ? "bg-success-300" : "bg-gray-400";

    return <button role={"button"} onClick={props.onClick}>
        <div
            className={"relative flex flex-col items-start gap-[4px] bg-gray-blue-100 py-[16px] px-[12px] rounded-[20px] min-h-[110px] h-[110px] overflow-hidden"}>
            <span
                className={"font-[500]"}>{user?.firstName?.value} {user?.lastName?.value}</span>
            {
                user?.interest?.value &&
                <span
                    className={"font-sf-pro text-gray-500 text-[11px] leading-[13px] px-[12px] py-[6px] bg-white border-[1px] border-gray-300 rounded-[16px]"}>
                    {interestValues[user?.interest?.value]}
            </span>
            }
            <div
                className={"absolute w-[214px] h-8 px-[12px] py-[8px] bottom-0 right-0 bg-gray-blue-400 rounded-tl-[16px]"}>
                <div className={"flex flex-row gap-[4px] items-center max-h-full"}>
                    <img src={PaceIcon}/>
                    <p className={"font-sf-pro font-[500] text-white text-[12px] leading-[16px]"}>
                        {
                            props.conversation.status === Conversation.Status.STATUS_LIVE ?
                                "Live" :
                                "Last conversation days ago"}
                    </p>
                </div>
            </div>
        </div>
    </button>
}

export default ConversationItem;