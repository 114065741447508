import {useContext, useEffect} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import useSession from "../../useSession";
import {useMutation} from "@tanstack/react-query";
import {PingSessionRequest} from "@hint-v2/api/astrologerapis/guidance/v1/session_pb";

export function usePingSession(sessionId?: string): void {
    const clients = useContext(APIClientsContext)
    const [session] = useSession()

    // Queries
    let pingMutation = useMutation({
        mutationFn: (sessionID: string) => {
            const req = new PingSessionRequest()
                .setSessionId(sessionID)
            return clients!.sessions.pingSession(req, {
                authorization: session,
            }).then((resp): void => {
              return;
            })
        },
    })

    useEffect(() => {
        console.log("active session id is: ", sessionId)
        if(!sessionId) {
            return;
        }

        let timer = setInterval(() => {
            if (sessionId) {
                pingMutation.mutate(sessionId)
            }
        }, 2000)
        return () => {
            clearInterval(timer)
        }
    }, [pingMutation, sessionId])
}