import TextArea from "../../../components/form/TextArea";
import Input from "../../../components/form/Input";
import UserProfileMainInfo from "../../../components/user/mainInfo/UserProfileMainInfo";
import {User} from "@hint-v2/api/models/user/v1/models_pb";
import UserBirthdayInfo from "../../../components/user/mainInfo/UserBirthdayInfo";
import {useUser} from "../../../hooks/api/users/useUser";
import {Conversation} from "@hint-v2/api/models/guidance/v1/models_pb";

interface UserInfoProps {
    conversation: Conversation.AsObject
}

export function UserInfo({conversation}: UserInfoProps) {
    const {data: user} = useUser(conversation.userId)

    if(!user) {
        return <div>Loading...</div>;
    }
    return <div className={"flex flex-col gap-3 rounded-[12px] border border-gray-300 p-4"}>
        <UserProfileMainInfo user={user}/>

        <div className={"w-full flex flex-col gap-3"}>
            <UserBirthdayInfo user={user}/>

            <div className={"flex flex-col gap-[6px]"}>
                <h2>MAIN INTERESTS</h2>
                <Input value={"Love reading"} />
            </div>
            <div className={"flex flex-col gap-[6px]"}>
                <h2>NOTES</h2>
                <TextArea />
            </div>
        </div>


    </div>
}