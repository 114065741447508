import {useContext, useEffect, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import useSession from "../../useSession";
import {
    useQuery,
    useQueryClient,
    UseQueryResult
} from "@tanstack/react-query";
import {RpcError} from "grpc-web";
import {User} from "@hint-v2/api/models/user/v1/models_pb";
import {
    ListVideoReadingsRequest,
    ListVideoReadingsResponse
} from "@hint-v2/api/astrologerapis/guidance/v1/video_reading_pb";
import {VideoReading} from "@hint-v2/api/models/guidance/v1/models_pb";
import {PaginationRequest, PaginationResponse} from "@hint-v2/api/common/v1/pagination_pb";

export function useVideoReadings(perPage: number):
    UseQueryResult<VideoReading.AsObject[], RpcError> & {setPage: (page: number) => void, pagination: PaginationResponse.AsObject}{
    const clients = useContext(APIClientsContext)
    const [session] = useSession()
    const [page, setPage] = useState(0)
    const [pagination, setPagination] = useState<PaginationResponse.AsObject>({
        page: 0,
        perPage: 0,
        total: 0,
        totalPages: 0
    })

    // Queries
    const query = useQuery<VideoReading.AsObject[], RpcError>({
        queryKey: ["video_readings"],
        queryFn: () => {
            const req = new ListVideoReadingsRequest()
                .setPagination(
                    new PaginationRequest()
                        .setPage(page)
                        .setPerPage(perPage)
                )
            return clients!.videoReadings
                .listVideoReadings(req, {
                    authorization: session,
                })
                .then((resp: ListVideoReadingsResponse): VideoReading.AsObject[] => {
                    return resp.toObject().dataList;
                })
        },
    })
    return {
        ...query,
        setPage,
        pagination,
    };
}