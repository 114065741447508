import React, {useState} from "react";
import {UserInfo} from "./UserInfo";
import {Chat} from "./Chat";
import {LeftBar} from "./LeftBar";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import {useAstrologerInfo} from "../../../hooks/api/astrologers/useAstrologerInfo";
import {useConversations} from "../../../hooks/api/conversations/useConversations";
import {Conversation} from "@hint-v2/api/models/guidance/v1/models_pb";

function Dashboard() {
    const {data: astrologer, error: astrologerErr, isLoading: astrologerLoading} = useAstrologerInfo()
    const [activeConversation, setActiveConversation] = useState<Conversation.AsObject | null>(null)
    let {data: conversations} = useConversations()

    if (astrologerErr) {
        return <div>{astrologerErr.message}</div>
    }


    return (
        <div className={"w-screen h-screen flex flex-col max-h-screen min-h-screen overflow-auto"}>
            <Header/>
            <div className="flex flex-row items-stretch flex-grow py-[32px] px-[112px]  overflow-auto max-h-full">
                <div className={"min-w-[346px] max-w-[346px] px-[12px] pt-[32px] pb-[16px] bg-white rounded-[12px]"}>
                    <LeftBar
                        astrologer={astrologer}
                        conversations={conversations ?? []}
                        onConversationClick={setActiveConversation}
                    ></LeftBar>
                </div>

                <div className={"mx-[18px] bg-gray-300 w-[1px] min-w-[1px]"}></div>
                {activeConversation &&
                    <div className={"flex flex-col flex-grow gap-3 min-w-[600px] "}>
                        <UserInfo conversation={activeConversation}></UserInfo>
                        <Chat conversation={activeConversation}></Chat>
                    </div>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default Dashboard;