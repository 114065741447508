import Logo from "../logo/Logo";

interface FooterProps {

}

function Footer(props: FooterProps) {
    return <div className="flex flex-row justify-between items-end bg-footer text-gray-300 w-full min-h-[114px] max-h-[114px] py-8 px-[80px]">
            <div className="flex h-12 content-center"><Logo className={"object-"}/></div>
            <div className="flex"> © 2023 Hint. All rights reserved</div>
    </div>
}

export default Footer;