import React from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import {useVideoReadings} from "../../../hooks/api/videoReadings/useVideoReading";
import VideoReadingItem from "./VideoReadingItem";

function Readings() {
    const {data: videoReadings, isLoading, error, pagination, setPage} = useVideoReadings(30)
    console.log(videoReadings)
    return (
        <div className={"flex flex-col max-h-screen min-h-screen"}>
            <Header/>
            <div className="flex flex-col items-stretch flex-grow gap-[32px] py-[32px] px-[112px]">
                <div className={"flex flex-row items-center justify-between"}>
                    <h2>Reading requests </h2>
                </div>
                <div className={"flex flex-col items-stretch gap-[23px]"}>
                    {videoReadings?.map((videoReading) =>
                        <VideoReadingItem videoReading={videoReading}/>)
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Readings;