import Toggle from "../../../components/form/Toggle";
import ConversationItem from "./ConversationItem";
import React, {useState} from "react";
import filterIcon from "../../../resources/icons/filter.svg"
import {useSetAstrologerStatusMutation} from "../../../hooks/api/astrologers/useSetAstrologerStatusMutation";
import {Astrologer, Conversation} from "@hint-v2/api/models/guidance/v1/models_pb";
import {useUsersByIDs} from "../../../hooks/api/users/useUser";
import StatusTag from "./StatusTag";

interface LeftBarProps {
    astrologer?: Astrologer.AsObject
    conversations: Conversation.AsObject[]
    onConversationClick?: (conversation: Conversation.AsObject) => void
}

export function LeftBar({astrologer, conversations, onConversationClick}: LeftBarProps) {
    const userIds = conversations.map((conversation) => conversation.userId)
    const userByIds = useUsersByIDs(userIds)


    let toggleChecked = astrologer?.status === Astrologer.Status.STATUS_ONLINE ||
        astrologer?.status === Astrologer.Status.STATUS_BUSY

    let setAstrologerStatusMutation = useSetAstrologerStatusMutation();
    let [filterVisible, setFilterVisible] = useState(false)
    const toggleFilter = () => setFilterVisible(!filterVisible);
    let filterClass = filterVisible ? "" : "hidden";

    let setMyStatus = (available: boolean) => {
        setAstrologerStatusMutation.mutate(available ? Astrologer.Status.STATUS_ONLINE : Astrologer.Status.STATUS_OFFLINE)
    }

    return <div className={"flex flex-col gap-5 items-center max-h-full"}>
        <p className={"font-semibold text-[24px] leading-[32px]"}>
            {`${astrologer?.firstName} ${astrologer?.lastName}`}
        </p>
        <div className={"relative w-[188px] h-[197px]"}>
            <img className={"rounded-[188px] aspect-square object-cover"} src={astrologer?.avatarUrl} alt={"Astrologer avatar"}/>
            <div className={"absolute bottom-0 left-1/2 transform -translate-x-1/2"}>
                <StatusTag status={astrologer?.status}></StatusTag>
            </div>
        </div>
        <div className={"flex flex-row gap-3 items-center "}>
            <p className={"font-semibold text-[20px] leading-[30px]"}>Current status</p>
            <Toggle checked={toggleChecked} onChange={setMyStatus}/>
        </div>
        <hr className={"w-full bg-gray-300/25"}/>
        <div className={"relative w-full pr-[16px]"}>
            <div className={"flex flex-row justify-between items-center"}>
                <p className={"font-semibold text-[36px] leading-[44px]"}> Conversations</p>
                <button onClick={toggleFilter}><img src={filterIcon}/></button>
            </div>
            <div hidden={!filterVisible}
                 className={`${filterClass} flex flex-col gap-[6px] absolute z-10 p-[12px] bg-gray-50 right-[16px] top-full rounded-[12px] shadow-[0px_2px_4px_-2px_rgba(16,24,40,0.06),_0px_4px_8px_-2px_rgba(16,24,40,0.10)]`}>
                <button className={"text-start"}>Recent conversations first</button>
                <button className={"text-start"}>Old conversations first</button>
                <button className={"text-start"}>Unread first</button>
                <button className={"text-start"}>By name</button>
            </div>
        </div>
        <div className={"flex flex-col w-full overflow-y-auto gap-3 pr-3"}>
            {conversations.map((conversation) => (
                <ConversationItem key={conversation.userId} conversation={conversation} onClick={() => {
                    onConversationClick && onConversationClick(conversation)
                }}></ConversationItem>
            ))}
        </div>
    </div>
}