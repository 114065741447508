import {useContext, useEffect, useMemo, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import useSession from "../../useSession";
import {
    useQueries,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "@tanstack/react-query";
import {RpcError} from "grpc-web";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";
import {GetUserRequest} from "@hint-v2/api/astrologerapis/user/v1/user_pb";
import {User} from "@hint-v2/api/models/user/v1/models_pb";
import {useNotifications} from "../../../hoc/notifications/WithNotificationsManager";
import {GetAstrologerRequest} from "@hint-v2/api/userapis/guidance/v1/astrolgers_pb";

export function useUser(userId: string): UseQueryResult<User.AsObject, RpcError> {
    const queryClient = useQueryClient()
    const clients = useContext(APIClientsContext)
    const [session] = useSession()
    const guidanceClient = clients!.astrologers

    // Queries
    return useQuery({
        queryKey: ["user", userId],
        queryFn: () => {
            const req = new GetUserRequest()
                .setUserId(userId)
            return clients!.users.getUser(req, {
                authorization: session,
            }).then((resp: User): User.AsObject => {
                return resp.toObject()
            })
        },
    })
}


//QueriesResults<Astrologer.AsObject[], RpcError[]>
export function useUsersByIDs(userIds: string[]): { [_: string]: User.AsObject } {
    const clients = useContext(APIClientsContext)
    const {addNotification} = useNotifications()
    const [session] = useSession()
    const usersClient = clients!.users
    const queryClient = useQueryClient()
    // Queries
    const queries = useQueries({
        queries: Array.from(new Set(userIds)).map((userId) => ({
            queryKey: ["astrologers", userId],
            queryFn: () => {
                const req = new GetUserRequest()
                    .setUserId(userId);

                return clients!.users.getUser(req, {
                    authorization: session,
                }).then((resp: User): User.AsObject => {
                    return resp.toObject();
                })
            },
        })),
    })

    return useMemo(() => {
        let result: { [_: string]: User.AsObject } = {}
        queries.forEach((astrologer) => {
            let a = astrologer as UseQueryResult<User.AsObject, RpcError>;
            if (!a.data) {
                return;
            }
            result[a.data.id] = a.data;
        })

        return result;
    }, [queries])
}