import Logo from "../logo/Logo";
import useSession from "../../hooks/useSession";
import {NavLink, NavLinkProps} from "react-router-dom";
import React from "react";
import {Notifications} from "./Notifications";

interface HeaderProps {

}

function Header(props: HeaderProps) {
    const [session] = useSession()
    if (!session) {
        return <div className="relative text-gray-700 flex flex-col items-center justify-center pb-3 border-b-2">
            <div className="flex h-12 content-center"><Logo/></div>
            <div className={"absolute top-[calc(100%+20px)] right-2"}><Notifications/></div>
        </div>
    }
    const activeLinkClassName: NavLinkProps["className"] = ({isActive}) => isActive ? 'text-primary-blue' : 'text-gray-600';

    return <div
        className="relative pr-20 pl-20 text-gray-700 flex flex-row items-center justify-between pb-3 border-b-2 min-w-[1000px]">
        <div className="flex h-12 content-center"><Logo className={"object-"}/></div>
        <div className={"flex flex-row gap-8"}>
            <NavLink className={activeLinkClassName} to={"/"}>Current Status</NavLink>
            <NavLink className={activeLinkClassName} to={"/profile"}>Personal Information</NavLink>
            <NavLink className={activeLinkClassName} to={"/readings"}>Readings</NavLink>
            <NavLink className={activeLinkClassName} to={"/rating"}>Rating and Reviews</NavLink>
            <NavLink className={activeLinkClassName} to={"/statistic"}>Statistics</NavLink>
        </div>
        <div className={"absolute top-[calc(100%+20px)] right-[30px]"}><Notifications/></div>
    </div>
}

export default Header;