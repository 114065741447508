import React, {useCallback, useEffect, useMemo} from "react";
import {useAgoraToken} from "./api/conversations/useAgoraToken";
import AC, {AgoraChat} from "agora-chat";
import {SendMsgResult} from "agora-chat/types/engineCore";


function useAgoraMessagesState() {
    let [messages, setMessages] = React.useState<AgoraChat.MessageBody[]>([])

    const addMessages = useCallback((newMessages: AgoraChat.MessageBody[]) => {
        setMessages((messages) => {
            let result = [...messages, ...newMessages]

            result.sort((a, b) => {
                if (a.type === "txt" && b.type === "txt") {
                    return a.time - b.time
                }
                return 1
            })

            return result
        })
    }, [setMessages])

    const clearMessages = useCallback(() => {
        setMessages([])
    }, [setMessages])

    return {
        messages,
        addMessages,
        clearMessages,
    }
}


interface useAgoraGroupChatParams {
    appKey: string
    groupId: string
    messagesPerPage: number,
    onTextMessage?: (message: AgoraChat.TextMsgBody) => void
}

export default function useAgora({appKey, groupId, messagesPerPage, ...params}: useAgoraGroupChatParams) {
    let [loggedIn, setLoggedIn] = React.useState(false);
    let [loginError, setLoginError] = React.useState(false);
    let {messages, addMessages} = useAgoraMessagesState()
    let [hasMoreMessages, setHasMoreMessages] = React.useState<boolean>(true)
    let [messagesCursor, setMessagesCursor] = React.useState<string | null>(null)
    let [agoraConnection, setAgoraConnection] = React.useState<AgoraChat.Connection | null>(null)

    const {
        data: agoraToken,
        refetch: refetchAgoraToken
    } = useAgoraToken()

    useEffect(() => {
        if (!agoraToken?.agoraToken) {
            return
        }
        let newConnection = new AC.connection({appKey: appKey})

        newConnection.open({
            user: agoraToken.agoraUserId,
            agoraToken: agoraToken.agoraToken,
        })
            .then(() => {
                setLoggedIn(true)
            })
            .catch((err) => {
                setLoginError(err)
            })
        newConnection.addEventHandler("connection&message", {
            onTextMessage: (message: AgoraChat.TextMsgBody) => {
                if (params.onTextMessage) {
                    params.onTextMessage(message)
                }

                addMessages([message])
            },
            onConnected: () => {
                setAgoraConnection(newConnection)
                setLoggedIn(true)
            },
            onTokenWillExpire: refetchAgoraToken
        })

        return () => newConnection.close();
    }, [agoraToken, setLoggedIn, params.onTextMessage, refetchAgoraToken, appKey])

    const sendTextMessage = useCallback((message: string) => {
        if (!agoraConnection || !agoraToken?.agoraUserId) {
            return
        }

        const msg = AC.message.create({
            from: agoraToken.agoraUserId,
            type: "txt",
            chatType: "groupChat",
            to: groupId,
            msg: message,
        })
        return agoraConnection
            .send(msg)
            .then((result: SendMsgResult) => {
                addMessages([msg])
                return result
            })
    }, [agoraToken?.agoraUserId, groupId, agoraConnection, addMessages])

    const loadMoreMessages = useCallback(() => {
        if (!agoraConnection) {
            return
        }
        agoraConnection.getHistoryMessages({
            cursor: messagesCursor,
            chatType: "groupChat",
            pageSize: messagesPerPage,
            targetId: groupId,
        }).then((result) => {
            if (result.cursor && result.cursor !== "undefined") {
                setMessagesCursor(result.cursor)
                setHasMoreMessages(true)
            } else {
                setHasMoreMessages(false)
            }

            addMessages(result.messages)
        })
    }, [agoraConnection, messagesCursor, messagesPerPage, groupId, addMessages])


    useEffect(() => {
        if (!agoraConnection) {
            return
        }

        // Initial messages load
        loadMoreMessages()
    }, [agoraConnection, loadMoreMessages])

    return {
        agoraLoggedIn: loggedIn,
        loading: !agoraConnection,
        loginError,
        agoraToken,
        agoraConnection,
        messages,
        sendTextMessage,
        loadMoreMessages,
        hasMoreMessages,
    }
}