import React from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

function Rating() {
    return (
        <div className={"flex flex-col max-h-screen min-h-screen"}>
            <Header/>
            <div className="flex flex-row items-stretch flex-grow py-[32px] px-[112px]">
                <div className={"flex flex-col gap-[18px] items-center min-w-[346px] max-w-[346px] px-[16px] py-[32px]"}>
                    <div className={"font-inter text-[24px] font-semibold leading-[32px]"}>Savannah Nguyen</div>
                </div>
                <div className={"mx-[18px] bg-gray-300 w-[1px] min-w-[1px]"}></div>
                <div className={"flex flex-col flex-grow gap-3 min-w-[600px]"}>
                    Right menu
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Rating;