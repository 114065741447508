import React from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import {useVideoReadings} from "../../../hooks/api/videoReadings/useVideoReading";
import {VideoReading} from "@hint-v2/api/models/guidance/v1/models_pb";
import {useUser} from "../../../hooks/api/users/useUser";
import {Gender} from "@hint-v2/api/common/v1/enums_pb";
import {User} from "@hint-v2/api/models/user/v1/models_pb";
import RelationshipStatus = User.RelationshipStatus;
import Arrow from '../../../resources/icons/arrow.svg'
import UserProfileMainInfo from "../../../components/user/mainInfo/UserProfileMainInfo";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import Button from "../../../components/form/Button";
import UserBirthdayInfo from "../../../components/user/mainInfo/UserBirthdayInfo";

interface VideoReadingItemProps {
    videoReading: VideoReading.AsObject
}

function VideoReadingItem({videoReading}: VideoReadingItemProps) {
    let [state, updateState] = React.useState<number>(0);
    let [open, setOpen] = React.useState(false);
    let {data: user, isLoading, error} = useUser(videoReading.userId);
    let height = open ? "h-[347px]" : "h-[112px]";
    if (!user) {
        return <div>Loading...</div>
    }

    const createdAt = new Timestamp()
        .setSeconds(videoReading.createdAt?.seconds || 0)
        .setNanos(videoReading.createdAt?.nanos || 0).toDate()

    const passedSeconds = Math.abs(Date.now() - createdAt.getTime()) / 1000; // seconds
    const secondsToReply = 60 * 60 * 24;
    let secondsLeft = Math.max(0, Math.floor(secondsToReply - passedSeconds)); // seconds
    let minutesLeft = Math.floor(secondsLeft / 60);
    let hoursLeft = Math.floor(secondsLeft / (60 * 60));

    if (secondsLeft > 0) {
        setTimeout(() => {
            updateState(state + 1);
        }, 1000)
    }

    return (
        <div className={`flex flex-col border-[1px] border-gray-300 rounded-[12px] overflow-hidden`}>
            <div className={`flex flex-row flex-1 gap-[12px] bg-gray-200 `}>
                <div className={"flex flex-row flex-1 justify-between p-4"}>
                    <UserProfileMainInfo user={user}/>
                    {
                        videoReading.status === VideoReading.Status.STATUS_PENDING &&
                        <div className={"flex flex-col items-center gap-[6px]"}>
                            <div
                                className={"flex flex-row items-center gap-[6px] font-semibold"}>
                                <p className={"text-error-500"}>{hoursLeft > 0 ? `${hoursLeft} HOURS` : minutesLeft ? `${minutesLeft} MINUTES` : `${secondsLeft} SECONDS`}</p>
                                <p className={""}>left to reply</p>
                            </div>
                            <Button>Send Reading</Button>
                        </div>
                    }
                </div>
                <div>
                    <button onClick={() => setOpen(!open)}
                            className={`h-full px-[24px] bg-gray-300 ${open && 'rotate-180'}`}>
                        <img alt={"details"} src={Arrow}/>
                    </button>
                </div>
            </div>
            {
                open &&
                <div className={"flex flex-col gap-3 p-4"}>
                    <UserBirthdayInfo user={user}/>
                </div>
            }
        </div>
    );
}

export default VideoReadingItem;