import React, {createContext, ReactElement} from "react";
import {Cookies, withCookies} from 'react-cookie';

export const SessionContext = createContext<[string, (session: string) => void]>(["", (val :String) => {}]);

interface AuthorizedProps {
    cookies: Cookies
    children: ReactElement | ReactElement[]
}

function sessionProvider(props: AuthorizedProps) {
    const session = props.cookies.get("session")
    const setSession = (val: String) => {
        props.cookies.set("session", val, {sameSite: "strict", secure: true})
    }

    return <SessionContext.Provider value={[session, setSession]}>{props.children}</SessionContext.Provider>;
}

export const SessionProvider = withCookies(sessionProvider);