interface TextAreaProps {
    onChange?: (value: string) => void
    value?: string
}

function TextArea({onChange, value}: TextAreaProps) {
    return <textarea value={value} onChange={(e) => {
        onChange && onChange(e.target.value)
    }} className={"px-[14px] py-[12px] min-h-[20px] rounded-[12px] border border-gray-300"} minLength={1}>
    </textarea>
}

export default TextArea;