import React, {Context, createContext, ReactElement, useEffect} from "react";
import {AuthServicePromiseClient} from "@hint-v2/api/commonapis/auth/v1/auth_grpc_web_pb";
import {AstrologerServicePromiseClient} from "@hint-v2/api/astrologerapis/guidance/v1/astrolgers_grpc_web_pb";
import {VideoReadingServicePromiseClient} from "@hint-v2/api/astrologerapis/guidance/v1/video_reading_grpc_web_pb";
import {UserServicePromiseClient} from "@hint-v2/api/astrologerapis/user/v1/user_grpc_web_pb";
import {ConversationServicePromiseClient} from "@hint-v2/api/astrologerapis/guidance/v1/conversation_grpc_web_pb";
import {AgoraServiceClient, AgoraServicePromiseClient} from "@hint-v2/api/commonapis/guidance/v1/agora_grpc_web_pb";
import {SessionServicePromiseClient} from "@hint-v2/api/astrologerapis/guidance/v1/session_grpc_web_pb";

export const APIClientCtx = createContext(null);

interface Clients {
    auth: AuthServicePromiseClient
    astrologers: AstrologerServicePromiseClient
    videoReadings: VideoReadingServicePromiseClient,
    users: UserServicePromiseClient,
    conversations: ConversationServicePromiseClient,
    agora: AgoraServicePromiseClient,
    sessions: SessionServicePromiseClient,
}

export const APIClientsContext: Context<Clients | null> = createContext<Clients | null>(null);

interface WithAPIClientsProps {
    children: ReactElement | ReactElement[]
}

export function WithAPIClients(props: WithAPIClientsProps) {
    const host = process.env.REACT_APP_API_URL!;

    const clients: Clients = {
        auth: new AuthServicePromiseClient(host, {}),
        astrologers: new AstrologerServicePromiseClient(host),
        videoReadings: new VideoReadingServicePromiseClient(host),
        users: new UserServicePromiseClient(host),
        conversations: new ConversationServicePromiseClient(host),
        agora: new AgoraServicePromiseClient(host),
        sessions: new SessionServicePromiseClient(host),
    }

    return <APIClientsContext.Provider value={clients}>{props.children}</APIClientsContext.Provider>;
}
