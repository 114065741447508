import {useContext, useEffect, useState} from "react";
import {APIClientsContext} from "../../../hoc/api/WithAPIClient";
import {APIError, wrapClientError} from "../errors";
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import useSession from "../../useSession";
import {RpcError} from "grpc-web";
import {useMutation, UseMutationResult, useQuery, useQueryClient} from "@tanstack/react-query";
import {useNotifications} from "../../../hoc/notifications/WithNotificationsManager";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";
import {UpdateMeRequest} from "@hint-v2/api/astrologerapis/guidance/v1/astrolgers_pb";

export function useUpdateAstrologerMutation(): UseMutationResult<Astrologer.AsObject, APIError, Astrologer.AsObject, void> {
    const clients = useContext(APIClientsContext)
    const {addNotification} = useNotifications()
    const [session] = useSession()
    const guidanceClient = clients!.astrologers
    const queryClient = useQueryClient()
    // Queries
    return useMutation({
        onSuccess: (data: Astrologer.AsObject) => {
            addNotification({
                title: "Profile update",
                message: `Success`,
                type: "success",
                hideAfter: 2000,
                notClosable: true,
            })
            return queryClient.invalidateQueries(["me"])
        },
        onError: (e: RpcError) => {
            addNotification({
                title: "Profile update",
                message: `Failed to update your profile: ${e.message}`,
                type: "error",
                hideAfter: 2000,
            })
        },
        mutationFn: (astrologer: Astrologer.AsObject) => {
            let req = new UpdateMeRequest()
                .setFirstName(astrologer.firstName)
                .setLastName(astrologer.lastName)
                .setTitle(astrologer.title)
                .setDescription(astrologer.description)
                .setServices(
                    new Astrologer.Services()
                        .setTextChat(
                            new Astrologer.Services.Session()
                                .setPricePerMinute(astrologer?.services?.textChat?.pricePerMinute || 0)
                                .setIsProvided(astrologer?.services?.textChat?.isProvided || false)
                        )
                        .setVoiceCalls(
                            new Astrologer.Services.Session()
                                .setPricePerMinute(astrologer?.services?.voiceCalls?.pricePerMinute || 0)
                                .setIsProvided(astrologer?.services?.voiceCalls?.isProvided || false)
                        )
                        .setVideoCalls(
                            new Astrologer.Services.Session()
                                .setPricePerMinute(astrologer?.services?.videoCalls?.pricePerMinute || 0)
                                .setIsProvided(astrologer?.services?.videoCalls?.isProvided || false)
                        )
                        .setVideoReadings(
                            new Astrologer.Services.Global()
                                .setIsProvided(astrologer?.services?.videoReadings?.isProvided || false)
                        )
                )
                .setFeaturesList(astrologer.featuresList)

            console.log(req.toObject())

            return guidanceClient.updateMe(req, {
                authorization: session,
            }).then((resp: Astrologer): Astrologer.AsObject => {
                return resp.toObject()
            })
        },
    })
}