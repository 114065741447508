import React, {useState} from 'react';

export enum ButtonColor {
    Blue = 1,
    Gray,
}

interface ButtonProps {
    color?: ButtonColor,
    isActive?: boolean,
    className?: string,

    onClick?: () => void
    children?: React.ReactNode
}

const styleClassNames = {
    [ButtonColor.Blue]: "bg-blue-600 text-white ",
    [ButtonColor.Gray]: "bg-gray-300 "
}


const hoverClassNames = {
    [ButtonColor.Blue]: "hover:bg-blue-700",
    [ButtonColor.Gray]: "hover:bg-gray-400",
}

function Button({color = ButtonColor.Blue, className, isActive = true, onClick, children}: ButtonProps) {
    let styleClassName = styleClassNames[color];
    if (isActive) {
        styleClassName += hoverClassNames[color];
    }

    return (
        <label className={`flex flex-row items-center cursor-pointer w-full rounded-[8px] min-w-[150px] ${styleClassName} ${className}`}>
            <button className={`px-[14px] py-[12px] w-full font-inter font-semibold`} onClick={onClick} onChange={onClick}>
                {children}
            </button>
        </label>
    );
}

Button.Blue = (props: ButtonProps) => <Button  {...props} color={ButtonColor.Blue}/>
Button.Gray = (props: ButtonProps) => <Button  {...props} color={ButtonColor.Gray}/>

export default Button;
