import React, {useEffect, useState} from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import AstrologerRating from "../../../components/astrologer-rating/AstrologerRating";
import Button, {ButtonColor} from "../../../components/form/Button";
import TextArea from "../../../components/form/TextArea";
import Input from "../../../components/form/Input";
import {useAstrologerInfo} from "../../../hooks/api/astrologers/useAstrologerInfo";
import {useUpdateAstrologerMutation} from "../../../hooks/api/astrologers/useUpdateAstrologerMutation";
import {Shimmer} from "react-shimmer";
import {Astrologer} from "@hint-v2/api/models/guidance/v1/models_pb";
import CameraIcon from "../../../resources/images/Camera.svg"
import SessionServiceSettings from "./SessionServiceSettings";
import GlobalServiceSettings from "./GlobalServiceSettings";
import {useSetAstrologerAvatarMutation} from "../../../hooks/api/astrologers/useSetAstrologerAvatarMutation";

interface Feature {
    id: string;
    name: string
}

const availableFeatures: Feature[] = [
    {id: "love_reading", name: "Love reading"},
    {id: "psychic_reading", name: "Psychic reading"},
    {id: "tarot_reading", name: "Tarot reading"},
    {id: "palmistry_reading", name: "Palmistry reading"},
    {id: "dream_analysis", name: "Dream analysis"},
    {id: "finance_and_career_reading", name: "Finance and Career reading"},
    {id: "other", name: "Other"},
]

interface ProfileData {
    firstName: string;
    lastName: string;
    textChatEnabled: boolean;
    textChatPrice: string;
    audioCallEnabled: boolean;
    audioCallPrice: string;
    videoCallEnabled: boolean;
    videoCallPrice: string;
    description: string;
}

function Profile(props: any) {
    let {data: astrologer, isLoading: astrologerLoading, error: astrologerErr} = useAstrologerInfo()
    let [modifiedAstrologer, setModifiedAstrologer] = useState<Astrologer.AsObject | null>(null)
    useEffect(() => {
        if (astrologer) {
            setModifiedAstrologer(astrologer);
        }
    }, [astrologer])
    let updateAstrologerMutation = useUpdateAstrologerMutation();
    let setAstrologerAvatarMutation = useSetAstrologerAvatarMutation();

    if (astrologerErr) {
        return <div>Error: {astrologerErr.message}</div>
    }

    const saveChanges = () => {
        if (modifiedAstrologer) {
            updateAstrologerMutation.mutate(modifiedAstrologer)
        }
    }
    const resetChanges = () => {
        setModifiedAstrologer(astrologer || null)
    }

    const setField = (field: keyof Astrologer.AsObject, value: any) => {
        setModifiedAstrologer(prevAstrologer => {
            if (prevAstrologer) {
                return {
                    ...prevAstrologer,
                    [field]: value,
                };
            }
            return null;
        });
    };

    const setService = (field: keyof Astrologer.Services.AsObject, value: any) => {
        setModifiedAstrologer(prevAstrologer => {
            if (prevAstrologer) {
                return {
                    ...prevAstrologer,
                    services: {
                        ...prevAstrologer.services,
                        [field]: value,
                    }
                };
            }
            return null;
        });
    }


    const featureStyle = (featureId: string) => {
        return modifiedAstrologer?.featuresList?.includes(featureId) ? "bg-primary-blue text-white" : "bg-gray-200"
    }

    const toggleFeature = (featureId: string) => {
        setModifiedAstrologer(prevAstrologer => {
            if (!prevAstrologer) {
                return null
            }
            let featuresList = [...prevAstrologer.featuresList] || []
            if (featuresList.includes(featureId)) {
                featuresList = featuresList.filter(feature => feature !== featureId)
            } else {
                featuresList.push(featureId)
            }

            return {
                ...prevAstrologer,
                featuresList: featuresList,
            };
        });
    }

    const setAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(123)
        if(!event.target.files){
            console.log(456)
            return
        }

        let reader = new FileReader();
        reader.onloadend = () => {
            setAstrologerAvatarMutation.mutate(reader.result as ArrayBuffer)
        }
        reader.readAsArrayBuffer(event.target.files[0]);
    }

    let avatarShimmer = <Shimmer className={"rounded-[188px]"} width={200} height={200}/>;
    let avatar = astrologer ?
        <img className={"rounded-[188px] aspect-square object-cover"} src={astrologer.avatarUrl}
             alt={"Astrologer avatar"}/> :
        avatarShimmer;
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Header/>
            <div className="flex flex-row items-stretch flex-grow py-[32px] px-[112px]">
                <div
                    className={"flex flex-col gap-[18px] items-center min-w-[346px] max-w-[346px] px-[16px] py-[32px]"}>
                    <div className={"relative w-[188px] h-[197px]"}>
                        {avatar}
                        <label htmlFor={"avatar"} className={"absolute bottom-0 right-0 cursor-pointer rounded-full"}>
                            <img className={"w-[50px] h-[50px]"} src={CameraIcon} alt="change avatar"/>
                        </label>
                        <input id={"avatar"} className={"hidden"} type="file" onInput={setAvatar}/>
                    </div>
                    <div className={"font-inter text-[24px] font-semibold leading-[32px]"}>
                        {astrologer ? `${astrologer.firstName} ${astrologer.lastName}` :
                            <Shimmer height={32} width={200}/>}
                    </div>
                    <div><AstrologerRating astrologer={astrologer}/></div>
                </div>
                <div className={"mx-[18px] bg-gray-300 w-[1px] min-w-[1px]"}></div>
                <div className={"flex flex-col flex-grow gap-8 py-[12px]"}>
                    <div className={"flex flex-row justify-between items-center gap-10"}>
                        <p className={"font-inter font-semibold text-[36px] leading-[44px]"}>Profile</p>
                        <div className={"flex flex-row gap-2"}>
                            <Button className={"py-[12px]"} color={ButtonColor.Gray}
                                    onClick={resetChanges}>Cancel</Button>
                            <Button className={"py-[12px]"} color={ButtonColor.Blue} onClick={saveChanges}>Save
                                changes</Button>
                        </div>
                    </div>
                    <div className={"flex flex-row gap-[6px]"}>
                        <div className={"flex flex-col gap-[6px] w-[50%]"}>
                            <p>FIRST NAME</p>
                            <Input onChange={(firstName) => setField("firstName", firstName)}
                                   value={modifiedAstrologer?.firstName || ""}/>
                        </div>
                        <div className={"flex flex-col gap-[6px] w-[50%]"}>
                            <p>LAST NAME</p>
                            <Input onChange={(lastName) => setField("lastName", lastName)}
                                   value={modifiedAstrologer?.lastName || ""}/>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-[6px] w-full"}>
                        <p>ROLE (OPTIONAL)</p>
                        <Input onChange={(role) => setField("title", role)} value={modifiedAstrologer?.title}/>
                    </div>
                    <div className={"flex flex-col gap-[12px] w-full"}>
                        {/*<div className={"flex flex-col gap-[6px] w-full"}>*/}
                        {/*    <p>EXPERTISE</p>*/}
                        {/*    <Input value={"I have some Expertise"}/>*/}
                        {/*</div>*/}
                        <div className={"flex flex-row gap-[12px] flex-wrap"}>
                            {availableFeatures.map((feature, index) => (
                                <button key={feature.id} onClick={() => toggleFeature(feature.id)}
                                        className={`flex flex-row gap-[6px] px-[14px] py-[12px] rounded-[12px] ${featureStyle(feature.id)}`}>{feature.name}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className={"flex flex-col gap-[6px] w-full"}>
                        <p>ABOUT ME</p>
                        <TextArea onChange={(val) => {
                            setField("description", val)
                        }} value={modifiedAstrologer?.description}></TextArea>
                    </div>
                    <div className={"flex flex-col gap-[6px] w-full"}>
                        <p>INTRODUCTORY VIDEO</p>
                        <p className={"bg-pink-50"}>JHelllo</p>
                    </div>
                    <div className={"flex flex-col gap-[12px] w-full"}>
                        <p>SERVICES</p>
                        {modifiedAstrologer ?
                            [
                                <SessionServiceSettings
                                    serviceName={"Text chat"}
                                    recommendedPrice={299}
                                    service={modifiedAstrologer?.services!.textChat!}
                                    onChanged={(service) => {
                                        setField("services", {
                                            ...modifiedAstrologer?.services ?? new Astrologer.Services(),
                                            textChat: service
                                        })
                                    }}
                                />,
                                <SessionServiceSettings
                                    serviceName={"Voice call"}
                                    recommendedPrice={499}
                                    service={modifiedAstrologer?.services!.voiceCalls!}
                                    onChanged={(service) => {
                                        setService("voiceCalls", service)
                                    }}
                                />,
                                <SessionServiceSettings
                                    serviceName={"Video call"}
                                    recommendedPrice={499}
                                    service={modifiedAstrologer?.services!.videoCalls!}
                                    onChanged={(service) => {
                                        setService("videoCalls", service)
                                    }}
                                />,
                                <GlobalServiceSettings
                                    serviceName={"Video Readings"}
                                    service={modifiedAstrologer?.services!.videoReadings!}
                                    onChanged={(service) => {
                                        setService("videoReadings", service)
                                    }}
                                />
                            ] : <Shimmer height={300} width={300}/>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
        ;
}

export default Profile;